.trainingNavigation {
  i {
    margin: 3px 15px 3px 0;
  }
  .video {
    .fa-play {
      visibility: hidden;
      margin: 3px 3px ;
    }

    &:hover {
      .fa-play {

        visibility: visible;
      }
    }
  }
}