.menu {
  width: 100%;
  height: 100%;
  background-color: #eee;
  z-index: -1;
  padding: 20px 0;

  .nav-item {
    &:hover {
      background-color: #ddd;
    }
    i {
      margin-right: 10px;
    }
  }
}