body {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



h1, h2, h3, h4 {
  font-family: Lustria, serif;
}
h2, h3, h4 {
  font-family: 'Lato', sans-serif;
}
h2 {
  /*padding-bottom: 10px;
  border-bottom: 5px solid #881621;*/
  font-weight: 700;
}
h4 {
  font-size: 17px;
}