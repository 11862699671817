header {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  z-index: 10;
  margin-bottom: 5px;
  .container {
    position: relative;
  }

  .dropdown {

    i {
      margin-right: 5px;
    }
  }

}

.navbar-brand, .navbar-brand:hover {
  height: 50px;
  font-family: Lustria, serif;
  font-size: 28px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
}
.navbar-brand img {
  height: 100%;
  margin-right: 13px;
  float: left;
}