
.progress .progress-bar {
  box-sizing: border-box;
  /*border-left: 1px solid #000;*/
  box-shadow: rgba(0, 0, 0, 0.15) 1px 0px 0px 0px inset, rgba(0, 0, 0, 0.15) 0px -1px 0px 0px inset;
}
.progress .progress-bar:first-of-type {
  border-left: 0;
}
.progress-bar-success, .progress-bar-warning {
  cursor: pointer;
}

.training {
  float: none;
  max-width: 95vw;
  min-height: 50vh;
  margin: 0 auto;
  position: relative;
}

.training-qa {
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #245273;
  border: 6px solid #FFF;
  box-shadow: 5px 5px 31px 5px rgba(0,0,0,0.5);
  z-index: 100;
  font-size: 3rem;
  color: #FFF;

  width: 85px;
  height: 85px;
  margin-bottom: 20px;
  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  opacity: 0.7;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }
}

.videoArea {
  position: relative;
  margin: 20px auto;
}
.training-debug {
  position: absolute;
  z-index: 100;
  right: 0;
  top: 0;
  background-color: rgba(189, 50, 14, 0.50);
  color: #FFF;
  .row {
    display: block;
    margin: 0;
    padding: 0;
  }
  .col-md-4 {
    display: block;
    margin: 0;
    padding: 3px;
    white-space: nowrap;
    font-weight: bold;
    text-align: right;
  }
  .col-md-8 {
    display: block;
    margin: 0;
    padding: 3px;
    white-space: nowrap;
  }
}

