footer {
  /* #af0606, #b9090b */
  background-color: #1c1c1d;
  color: #FFF;
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.3);
  margin-top: 5px;

  h3 {
    padding-bottom: 3px;
    color: #881621;
    border-bottom: 1px solid #FFF;
  }

  .container {
    padding-top: 50px;
    padding-bottom: 50px;
    //padding: 50px 0 50px 0;

    .small {
      display: block;
    }

    & ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    & ul>li>a, & ul>li>a:hover, & ul>li>a:focus {
      color: #FFF;
    }
  }



}

