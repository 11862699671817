
.back {
  min-height: 90vh;
  width: 100%;
  background: url('../../img/studio.jpg') 50% 0 fixed;
  background-color: #FFF;
  background-size: cover;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: -25px;
  margin-top: -5px;
}


.splash {
  margin-top: 20vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;

  h1, h2 {
    font-family: 'Lustria', serif;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 2px 3px;
    color: #FFF;
  }
  h1 {
    font-size: 50px;
    margin: 0;
  }
  h2 {
    font-size: 38px;
    margin: 8px 0;

    strong {
      color: #881621;
    }
  }
}

.form-signin {
  max-width: 330px;
  margin: 0 auto;
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;

  .form-signin-heading, .form-signin .checkbox {
    margin-bottom: 10px
  }

  .checkbox {
    font-weight: normal
  }

  .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px
  }

  .form-control:focus {
    z-index: 2
  }

  input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }
}

.error-box {
  font-size: 16px
}
.error-box>.glyphicon {
  vertical-align: -3px;
  font-size: 22px
}
